var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Layout, Dependencies } from "~/core/decorator";
import { State, namespace } from "vuex-class";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { CaseCollectorService } from "~/services/business-service/case-collector.service";
import NumberRange from "~/components/common/number-range.vue";
import { CollectionData } from "~/config/types/collection-data";
var collectionManageModule = namespace("collection-manage");
//@Auth(123)
var FastQuery = /** @class */ (function (_super) {
    __extends(FastQuery, _super);
    function FastQuery() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.principalId = null;
        _this.dataSet = [];
        _this.queryModel = {
            principalId: '',
            personalName: "",
            certificateNo: "",
            batchNumber: "",
            city: "",
            delegationDate: [],
            endCaseDate: [],
            stage: "",
            phone: "",
            cardNo: "",
            address: "",
            employerName: "",
            leftAmt: {
                min: '',
                max: ''
            },
            overdueAmtTotal: {
                min: '',
                max: ''
            },
            overduePeriods: {
                min: '',
                max: ''
            },
            overdueDays: {
                min: '',
                max: ''
            },
            comment: '',
            caseType: "",
        };
        _this.rules = {
            certificateNo: [{ min: 4, message: '请输入至少4位的身份证号', trigger: 'blur' }],
            phone: [{ min: 4, message: '请输入至少4位的手机号', trigger: 'blur' }],
        };
        _this.mOtherData = {
            isFast: true
        };
        return _this;
    }
    FastQuery.prototype.onBatchNumberClick = function (currentCollection) {
        var data = new CollectionData(currentCollection.principalId, currentCollection.id, this.queryModel, this.mOtherData);
        this.openExecutionPage(data);
    };
    FastQuery.prototype.mounted = function () {
    };
    FastQuery.prototype.handleResetForm = function () {
        this.queryModel.principalId = '';
        this.queryModel.overdueAmtTotal.min = '';
        this.queryModel.overdueAmtTotal.max = '';
        this.queryModel.leftAmt.min = '';
        this.queryModel.leftAmt.max = '';
        this.queryModel.overduePeriods = {};
        this.queryModel.overdueDays = {};
    };
    FastQuery.prototype.refreshData = function () {
        var _this = this;
        // 判断查询实体是否全部为空
        var key = Object.values(this.queryModel).filter(function (x) {
            if (x instanceof Object) {
                return !!Object.values(x).filter(function (sub) { return !!sub; }).length;
            }
            else if (Array.isArray(x)) {
                return !!x.filter(function (array) { return !!array; }).length;
            }
            else {
                return !!x;
            }
        });
        if (key.length) {
            this.loading.state = true;
            // 根据实体的内容进行查找
            this.caseCollectorService.findAllInCollectCase(this.queryModel, this.pageService, this.sortService, this.loading).subscribe(function (data) {
                _this.dataSet = data;
            });
        }
        else {
            this.$message("请至少填写一项查找条件");
        }
    };
    __decorate([
        Dependencies(PageService)
    ], FastQuery.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], FastQuery.prototype, "sortService", void 0);
    __decorate([
        Dependencies(CaseCollectorService)
    ], FastQuery.prototype, "caseCollectorService", void 0);
    __decorate([
        State
    ], FastQuery.prototype, "principalList", void 0);
    __decorate([
        collectionManageModule.Action
    ], FastQuery.prototype, "openExecutionPage", void 0);
    FastQuery = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                NumberRange: NumberRange
            }
        })
    ], FastQuery);
    return FastQuery;
}(Vue));
export default FastQuery;
